import React from 'react'

import SEO from 'src/components/seo/index'
import Header from 'src/components/header/setting'

import styles from './index.module.scss'


const AgreementPage = () => {

    return (
        <div>
            <SEO title="会員会則" />
            <Header hasBack={true} />

            <div className={styles.box}>
                <h1>AGREEMENT</h1>

                <section className={styles.block}>
                    <h2>WE ARE THE FIT 会員会則</h2>

                    <section>
                        <h3>第１条（定義）</h3>
                        <div>
                            <p>本会則によって定める条項はWE ARE THE FIT （以下「当施設」という）に適用されるものとする。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第２条（目的）</h3>
                        <div>
                            <p>当施設は本会則に則り、当施設会員が施設を利用し、技術力の向上、心身の育成、健康維持、健康増進及び会員相互の親睦並びにフィットネスライフの振興を図ることを目的とする。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第３条（管理運営）</h3>
                        <div>
                            <p>当施設は、「快適ライフサービス株式会社」（以下「当社」という）が経営し、管理運営を行う。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第４条（会員制度）</h3>
                        <div>
                            <p>当施設は会員制とする。</p>
                            <ul className={styles.dotList}>
                                <li>当施設に入会しようとする者は、本会則を承認し本会則に基づく諸契約を当社と締結しなければならない。</li>
                                <li>会員の当施設の利用範囲、条件及び特典については別に定める。</li>
                                <li>会員は、当施設を利用するときは、常に会員証を提示しなければならない。</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h3>第５条（入会資格）</h3>
                        <div>
                            <ol className={styles.alphaList}>
                                <li>
                                    <p>当施設の入会資格は以下各号の定める通りとする。</p>
                                    <ul className={styles.dotList}>
                                        <li>本規約および諸規則を遵守できる方</li>
                                        <li>刺青（タトゥー含む）等をしていない方。ただし別途会社が定める基準に従い、会社が認める場合を除く。</li>
                                        <li>暴力団、暴力団関係企業もしくはこれらに準ずる者またはその構成員その他の反社会的勢力ではない方</li>
                                        <li>医師等により運動を禁じられておらず、当施設の利用に支障がないと申告された方</li>
                                        <li>他のお客様に伝染または感染する恐れのある疾病を有しない方</li>
                                        <li>過去の会費、事務手数料、利用料等について未払いの債務のない方</li>
                                        <li>過去に当社または他社が運営するスポーツクラブその他同様のサービスにおいて、除名またはこれに類する処分を受けたことがない方</li>
                                        <li>前各号に定めるほか、当社が適当と認めた方</li>
                                    </ul>
                                </li>
                                <li>
                                    <p>妊娠中の方については、原則として当施設を利用することは認めない。ただし、別途当社が妊娠中の方を対象にしたプログラムを提供する場合は、この限りではない。</p>
                                </li>
                                <li>
                                    <p>性同一性障害その他のセクシャルマイノリティの方については、当社が定める基準に従い個別に検討した上で、本施設の利用を認めることがある。</p>
                                </li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第６条（会員資格）</h3>
                        <div>
                            <p>当施設への入会を希望する者は、第４条第１項第１号の契約が完了し、規定料金の納入により、合意した期日から会員資格を取得するものとする。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第７条（未成年者の取扱い）</h3>
                        <div>
                            <p>未成年者が会員になろうとするときは、その親権者が同意した上で、申し込むものとする。尚、親権者は、法令に定めがある場合を除いて、自ら会員となった場合と同様に、本会則に基づく責任を本人と連帯して負うものとする。対象の未成年者は16歳以上とする。それ以外の未成年者は原則会員登録を行えないものとする。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第８条（会員資格譲渡等の禁止）</h3>
                        <div>
                            <p>当施設の会員資格は、本会則に別段の定めのある場合を除き会員本人に専属するものとし、他への譲渡、貸与等の処分をすることはできない。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第９条（入会金・事務登録料・会費・手数料等）</h3>
                        <div>
                            <p>会員区分に従う入会金、事務登録料、会費、手数料等（以下「会費等」という）は別に定める。</p>
                            <ul className={styles.dotList}>
                                <li>会員は別に定める会費等支払期日までに、それぞれの会費等を払い込まなければならない。尚、支払いに要する費用は会員の負担とする。</li>
                                <li>一旦納入した会費等は、本会則または法令に定めがある場合を除いて、これを返還しない。</li>
                                <li>会費等に賦課される消費税等は会員の負担とする。尚、消費税率等の変更など消費税法等の改正等がされる場合、会員の負担は当該改正等の内容に従い変更される。また、年一括払いによる場合、当該改正等が適用される期間に相当する部分の会費等に賦課される消費税等については、会員が当該改正等の内容に従い負担するものとし、会社の指示に従い当該負担を前払いするか、差額を追加で負担する。</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h3>第10条（諸規則の遵守）</h3>
                        <div>
                            <ol className={styles.alphaList}>
                                <li>
                                    <p>会員は当施設利用にあたり、本会則、入会のしおり記載事項、その他館内諸規則を遵守しなければならない。尚、下記の行為は禁止とする。</p>
                                    <ul className={styles.dotList}>
                                        <li>酒気を帯びての入館</li>
                                        <li>他の会員の当施設利用を妨げる行為</li>
                                        <li>施設スタッフの指示に反する行為</li>
                                        <li>他の会員を含む第三者(以下「他の方」という)や施設スタッフ、当施設、会社を誹謗、中傷する行為</li>
                                        <li>他の方や施設スタッフを殴打したり、身体を押したり、拘束したりする等の暴力行為</li>
                                        <li>大声、奇声を発したり、他の方や施設スタッフの行く手を塞いだり、唾を吐いたりする等の威嚇行為や迷惑行為</li>
                                        <li>物を投げたり、壊したり、叩いたりするなど、他の方や施設スタッフが恐怖・畏怖・困惑を感じる危険行為や迷惑行為</li>
                                        <li>当施設の器具・備品の損壊や持ち出し</li>
                                        <li>他の方や施設スタッフを待ち伏せしたり、後をつけたり、みだりに話しかけたりする等の行為</li>
                                        <li>正当な理由なく、面談、電話、その他の方法で施設スタッフを拘束・束縛する等の迷惑行為</li>
                                        <li>痴漢、のぞき、露出等の違法行為や迷惑行為</li>
                                        <li>刃物など危険物の施設内への持ち込み</li>
                                        <li>当社の許可なく、当施設での物品販売やパーソナルトレーニング等の営業行為、勧誘行為、金銭の貸借、政治活動、署名活動をする等の行為</li>
                                        <li>高額な金銭、貴重品の施設内への持ち込み</li>
                                        <li>動物の施設内への持ち込み</li>
                                        <li>当施設の秩序を乱す行為</li>
                                        <li>その他、法令または公序良俗に反する行為、当社が会員としてふさわしくないと認める行為</li>
                                    </ul>
                                </li>
                                <li>
                                    <p>会員が前各項のいずれかに違反した場合、当社はその会員を退館させることができる。</p>
                                </li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第11条（損害賠償責任）</h3>
                        <div>
                            <p>会員の責に帰する事由により会員が受けた損害に対して、当社はその損害賠償の責を負わない。</p>
                            <ul className={styles.dotList}>
                                <li>当施設内で発生した盗難、傷害その他の事故については、それが当社の責に帰すべき事由による場合を除き当社は責任を負わない。</li>
                                <li>会員間に生じたトラブルについては当事会員間にて解消するものとし、当社は一切その責を負わない。</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h3>第12条（会員等の損害賠償責任）</h3>
                        <div>
                            <p>会員の責に帰する事由により当社または第三者に損害を与えた場合、その会員が賠償の責を負うものとする。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第13条（会員資格喪失）</h3>
                        <div>
                            <ol className={styles.alphaList}>
                                <li>
                                    <p>会員は次の各号に該当する場合、第１号については当社の指定する日、第２号、第３号及び第４号については該当事由の発生日をもってその会員資格を喪失し、以後、会員としての如何なる権利をも喪失する。この場合速やかに会員証を当社に返還しなければならない。</p>
                                    <ul className={styles.dotList}>
                                        <li>会員の都合により退会を申し出、当社の指定する手続きを行った場合</li>
                                        <li>第14条により除名された場合</li>
                                        <li>第16条第１項の各号のいずれかに該当することとなった場合</li>
                                        <li>会員本人が死亡した場合</li>
                                    </ul>
                                </li>
                                <li>
                                    <p>経営上やむを得ない事由により当施設の全部を閉鎖した場合、当該時点にて会員は会員資格を喪失するものとする。</p>
                                </li>
                                <li>
                                    <p>経営上やむを得ない事情により当施設の一部を閉鎖した場合、以下の場合を除き、当該施設にて入会手続きを行った会員は、閉鎖した時点にて会員資格を喪失するものとする。</p>
                                    <ul className={styles.dotList}>
                                        <li>閉鎖した施設以外の当施設を利用できる会員に該当し、閉鎖するまでに、当社の指定する手続きを行った場合</li>
                                        <li>閉鎖した施設以外の当施設を利用できる会員に変更を希望し、閉鎖するまでに当社の指定する手続きを行った場合</li>
                                    </ul>
                                </li>
                                <li>
                                    <p>会員が前各項により会員資格を喪失した場合、当社は、受領済みの会費から当社所定の方法により計算した既経過期間に相当する部分の会費を控除した残額がある場合は、これを遅滞なく会員に返還し、その他の入会金、事務登録料、手数料等については返還しないものとする。尚、第１項の場合、年一括払いの会員については、当社所定の方法により既経過期間に相当する部分の会費を計算するに当たっては、月会費の金額を基準とするものとする。</p>
                                </li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第14条（会員除名）</h3>
                        <div>
                            <p>会員が次の各号に該当する場合、当社はその会員を当施設から除名することができる。</p>
                            <ul className={styles.dotList}>
                                <li>当施設の会則、入会のしおり記載事項、その他館内諸規則に違反した場合</li>
                                <li>当施設の名誉を傷つけ、秩序を乱し、または当施設会員として相応しくない行為を行った場合</li>
                                <li>会費等の支払いを２ヵ月間怠った場合</li>
                                <li>当社に対し虚偽の申告・申出・届出等をしたことが判明した場合</li>
                                <li>第11条第１項第２号に基づく退館指示を繰り返し受けた場合</li>
                                <li>第16条第１項各号のいずれかに該当することを偽って施設を利用した場合</li>
                                <li>前各号の他、当社が当施設会員としてふさわしくないと認めた場合</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h3>第15条（施設の一時的閉鎖・一時的休業）</h3>
                        <div>
                            <ol className={styles.alphaList}>
                                <li>
                                    <p>次の場合当社は、当施設の全部または一部の閉鎖、若しくは休業をすることができる。その場合、第４号または第５号を除き、１週間前までにその旨を告知する。</p>
                                    <ul className={styles.dotList}>
                                        <li>定期休業等による場合</li>
                                        <li>当社が特別行事を開催する場合</li>
                                        <li>施設の増改築、修繕または点検によりやむを得ない場合</li>
                                        <li>気象災害、その他外因的事由により、その災害が会員に及ぶと当社が判断した場合</li>
                                        <li>前各号の他、施設の安全上、その他重大な事由によりやむを得ない場合</li>
                                    </ul>
                                </li>
                                <li>
                                    <p>前項告知は、当施設内の所定の掲示場所に掲示することをもって足りるものとする。</p>
                                </li>
                                <li>
                                    <p>第１項の措置により会員の会費等支払い義務は、軽減または免除されない。但し、２週間を超えて閉鎖若しくは休業となる場合または法令に定めのある場合は、その期間に相応する会費を減額する。</p>
                                </li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第16条（利用の禁止）</h3>
                        <div>
                            <p>次の各号に該当する者の施設利用はこれを禁止する。</p>
                            <ul className={styles.dotList}>
                                <li>暴力団関係者、その他反社会的勢力構成員</li>
                                <li>刺青のある者（タトゥシールを含む）</li>
                                <li>伝染病、その他、他の方や施設スタッフに伝染または感染する恐れのある疾病を有する者</li>
                                <li>一時的な筋肉の痙攣や、意識の喪失などの症状を招く疾病を有する者</li>
                                <li>判断能力・身体能力の欠如・不十分、疾病、高齢などにより施設を一人で利用できないと会社が判断した者</li>
                                <li>医師から運動または入浴を禁じられている者</li>
                                <li>妊娠している者（マタニティプログラムは除く）</li>
                                <li>当施設の会員としてふさわしくないと当社が判断した者</li>
                                <li>過去に当社より除名等の通告を受けた者または当施設以外の会員制スポーツクラブ等より除名等の通告を受けた者</li>
                                <li>前各号の他、正常な施設利用ができないと当社が判断した者</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h3>第17条（変更事項の届出）</h3>
                        <ul className={styles.dotList}>
                            <li>会員は、氏名、住所、電子メールアドレス等の連絡先、その他入会申込書記載事項に変更があった場合には、速やかに届出るものとする。</li>
                            <li>当施設の会員への諸通知等は、会員から 届出があった最新の連絡先宛に行うものとし第１項の届出を怠ったため、当社からなされた諸通知等が延着し、または到達しなかった場合には、通常到達すべき時に到達したものとみなす。</li>
                        </ul>
                    </section>

                    <section>
                        <h3>第18条（諸会費の変更）</h3>
                        <ul className={styles.dotList}>
                            <li>当社は、第９条に基づいて会員が負担するべき会費等を変更することができる。但し会費については、２ヶ月前までに会員に告知するものとする。</li>
                            <li>前項の告知は、会員が当社に届出た連絡先宛に通知し、かつ当施設内の所定の掲示場所に掲示することにより行うものとする。</li>
                        </ul>
                    </section>

                    <section>
                        <h3>第19条（退会・休会・解約）</h3>
                        <ul className={styles.dotList}>
                            <li>退会、休会・オプションの変更、解約、の申し出は毎月10日を締日とし、末日に適用とする。11日以降の申し出は翌月末の適用とする。</li>
                            <li>未納金が発生している場合の退会・休会申請は、未納金支払い後の受理とする。</li>
                            <li>退会、休会・オプションの変更、解約の申し出は、店頭にて行うものとする。</li>
                            <li>休会制度は一度の手続きで、連続した制度利用は最大6ヵ月間とする。</li>
                            <li>休会期間は月額1,000円（税込）の休会費が発生する。</li>
                            <li>休会をした月に退会をすることはできず、既に退会を申請している月の休会は受理しないものとする。</li>
                            <li>休会中に退会届を提出した場合、翌月は復会となり月会費が発生する。</li>
                            <li>妊娠による休会については、手続きより2年間を特別休会期間とし、期間中の休会費は発生せず、適用されるものとする。</li>
                        </ul>
                    </section>

                    <section>
                        <h3>第20条（クレジットカード登録・未納金の発生）</h3>
                        <ul className={styles.dotList}>
                            <li>未納金が発生した場合速やかに当社指示に従い支払い手続きを取るものとする。</li>
                            <li>登録クレジットカードが使用不可、有効期限更新、支払いに影響が発生する場合速やかに当社へ連絡する義務を有する。</li>
                            <li>未納金が発生し、1ヵ月以上会員本人と連絡が取れない場合は、緊急連絡先に連絡するものとする。</li>
                        </ul>
                    </section>

                    <section>
                        <h3>第21条（会則の改定）</h3>
                        <ul className={styles.dotList}>
                            <li>当社は、必要に応じて会則等の改定を行うことができる。尚、改定した会則等の効力は全会員に及ぶものとする。</li>
                            <li>当社は、会則等の改定を行うときは、１ヵ月前までに施設内に掲示し、且つ、当施設のウェブサイトへ掲載することにより、これを会員に告知するものとする。</li>
                        </ul>
                    </section>

                    <div className={styles.revision}>
                        <p>2019年12月25日改定</p>
                        <p>2020年12月25日時点WE ARE THE FIT都立大店の既存会員様は2021年1月25日からの適用となります。</p>
                    </div>
                </section>

                <section className={styles.block} id="body-styling">
                    <h2>Body Styling 会員会則</h2>

                    <div className={styles.headText}>
                        <p>本規程は、快適ライフサービス株式会社が運営管理する各施設（以下各施設を総称して「本施設」といいます）の利用に関して定めるものです。</p>
                    </div>
                    
                    <section>
                        <h3>第1条(運営管理会社)</h3>
                        <div>
                            <p>本施設の運営管理は快適ライフサービス株式会社（以下「当社」といいます。）があたります。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第2条(目的)</h3>
                        <div>
                            <p>本施設はスペース及び美容用品時間貸事業とします。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第3条(会員)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>当社が本施設の利用を承認した方を会員といい、会員の種類は各施設ごとに定めます。なお会員種類の廃止、利用条件の変更ついては当社の判断により変更することがあります。</li>
                                <li>本施設の会員資格は、本人に限り認められるものであり、当社の事前許可なく、第三者（親族を含みます）に利用させるほか、譲渡ないし貸与することはできません（以下、これらの行為を「不正使用」といいます。</li>
                                <li>本施設の会員が、他の会員や本施設従業員その他第三者に対し、局部の露出、意図的な身体的接触、その他のわいせつな言動を行った場合には、即座に本施設の利用を中止しご退店いただくとともに、以後の利用を禁止させていただきます。また、局部の露出等の問題行動が行われた場合、警察へ通報等を行うこともございます。</li>
                                <li>会員は、当社が、前項より、本施設の利用中止及び以後のご利用の禁止等の措置をとった場合であっても、入会金および会費の返金の請求はできないものとします。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第4条(入会資格)</h3>
                        <div>
                            <p>本施設の会員は、次の各号のすべて満たす方に限ります。当社は、次の各号の事由によらず、入会に適さないと判断した方が会員となることを拒むことができます。</p>
                            <ol className={styles.numList}>
                                <li>本施設の目的と趣旨に賛同し本規程、その他当社の定める規則を遵守できる方</li>
                                <li>健康状態に異常がなく、医師から運動やエステを禁止されていない方</li>
                                <li>成年被後見人、被保佐人または被補助人ではない方</li>
                                <li>心臓病、高血圧症、皮膚病、伝染病、精神病及びこれに類する疾患のない方</li>
                                <li>暴力団、暴力団関係者企業もしくはこれらに準ずる者、または、その構成員、その他の反社会的勢力ではない方</li>
                                <li>満１８歳以上２０才未満の場合、入会に際し保護者の同意を会社所定の書類にて得た方。この場合、保護者は本規程に基づく責任を本人と連帯して負うものとします。</li>
                                <li>過去に会員から除名となっていない方、過去に会員として在籍していた際の会費及び諸料金を滞納していない方</li>
                                <li>その他、当社において会員資格があると認められた方</li>
                                <li>性同一性障害その他のセクシャルマイノリティの方については、当社が定める基準に従い個別に検討した上で、本施設の利用を認めることがあります。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第5条(入会契約の締結及び手続き)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>本施設の会員となることを希望される方は、申込手続きをおこない、当社が定める入会金を納入していただきます。</li>
                                <li>会員資格は、前号に定める事項の全部を完了し、当社の審査を経て、当社の承認を得られたときに発生します。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第6条(入会金)</h3>
                        <div>
                            <p>入会金は、当社が定める金額とします。一旦支払われた入会金は理由の如何に関わらず返金いたしかねます。但し、入会申込に際し行う会員資格審査のうえお断りした場合は、返金いたします。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第7条(会費)</h3>
                        <div>
                            <p>会費は当社が別に定める額とし、会員は当社が定める方式により会費をお支払いいただきます。なお、会員制クラブですのでご利用のない月も会費のお支払いは必要になります。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第8条(会費の返金)</h3>
                        <div>
                            <p>一旦支払われた会費は、理由の如何に関わらず返金致しかねます。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第9条(利用資格)</h3>
                        <div>
                            <p>次の各号に該当する方は本施設をご利用できません。</p>
                            <ol className={styles.numList}>
                                <li>飲酒、体調不良等により、正常な施設利用ができないと当社が判断した方</li>
                                <li>刃物等危険物をお持ちの方</li>
                                <li>妊娠されている方</li>
                                <li>利用料金を滞納されている方</li>
                                <li>その他第４条の各号に違反するまたは違反するおそれがあると当社が判断した方</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第10条(会員証)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>当社は会員に対し、会員資格を証するため、会員証を交付します。</li>
                                <li>前項により会員証を交付された会員は、本施設入場に際して会員証を持参して提示するものとします。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第11条(利用料)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>会員は本施設を利用する場合、当社が定める利用料を支払うものとします。</li>
                                <li>支払済みの利用料については、一切返金いたしません。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第12条(施設利用)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>会員はその種類に応じ本施設を利用できます。利用範囲については別途定めます。</li>
                                <li>当社は本施設の一部を予約制とし、利用時間を制限することができます。</li>
                                <li>当社は施設利用の円滑化を図るため、本施設の利用時間・利用回数・利用人数を制限することができます。特に、一部時間帯において他のお客様による施設の利用状況により、お客様による施設利用を一時的に制限し、お待ち頂く場合がございますので、あらかじめご了承下さい。</li>
                                <li>当社は、会員が本施設で利用できるマシンの範囲を決定することができるものとし、会員に特定のマシンを利用できることを保証するものではございません。</li>
                                <li>当社は、本施設の防犯管理上、モニターを設置させていただいております。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第13条(施設の一時的閉鎖・一時的休業)</h3>
                        <div>
                            <p>次の場合当社は、当施設の全部または一部の閉鎖、若しくは休業をすることができる。その場合1週間前までにその旨を告知する。</p>
                            <ol className={styles.numList}>
                                <li>定期休業等による場合</li>
                                <li>当社が特別行事を開催する場合</li>
                                <li>施設の増改築、修繕または点検によりやむを得ない場合</li>
                                <li>気象災害、その他外因的事由により、その災害が会員に及ぶと当社が判断した場合</li>
                                <li>前各号の他、施設の安全上、その他重大な事由によりやむを得ない場合</li>
                            </ol>
                            <p>前項告知は、当施設内の所定の掲示場所に掲示することをもって足りるとする。第1項の措置により会員の会費等支払い義務は、軽減または免除されない。</p>
                            <p>但し、２週間を超えて閉鎖若しくは休業となる場合または法令に定めのある場合はその期間に相応する会費を減額する。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第14条(施設利用上の注意事項)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>本施設内では電話や話し声などは他のお客様のご迷惑になりますので、ご遠慮ください。</li>
                                <li>マシン使用中に携帯電話やiPad等電子機器その他の所持品の故障があった場合、本施設では一切責任を負いません。</li>
                                <li>貴重品はお客様ご自身で管理してください。盗難・紛失等があった場合、本施設は一切責任を負いません。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第15条(会員資格の譲渡及び名義変更)</h3>
                        <div>
                            <p>会員の資格は、当社が承認した場合を除き、会員資格を第三者に譲渡または名義変更すること、並びに担保の差入等の処分をすることはできません。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第16条(会員資格の喪失)</h3>
                        <div>
                            <p>会員が次号のいずれかに該当した場合には、その資格を失います。会員資格の喪失時期は会員が該当したそのときとなります。</p>
                            <ol className={styles.numList}>
                                <li>退会したとき</li>
                                <li>死亡したとき</li>
                                <li>第４条に定める会員資格に適合しなくなったとき</li>
                                <li>第17条により除名されたとき</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第17条(除名)</h3>
                        <div>
                            <p>会員が次号のいずれかに該当する場合、当社は会員を除名できます。この場合において、会員は損害賠償の請求をおこなうことができません。</p>
                            <ol className={styles.numList}>
                                <li>提出する書類に虚偽の申告をしたとき</li>
                                <li>本規程・規則・その他の規程に反する行為があったとき</li>
                                <li>本施設又は、会社の名誉又は信用が傷つけられたとき</li>
                                <li>他の会員との協調を欠き、その他の設備管理運営の秩序を乱したとき</li>
                                <li>本施設の設備などを故意に損壊したとき</li>
                                <li>会費その他諸支払いを滞納し、支払いの督促に応じないとき</li>
                                <li>入会後に第４条、第９条に適合しない事由が判明したとき</li>
                                <li>その他、会員としての品位を損なうと認められる行為があったとき</li>
                                <li>本施設内での営業活動及び販売行為が認められたとき</li>
                                <li>本施設の利用に際して不当且つ不合理な要求をなすなどして当社・従業員を著しく困惑させたとき</li>
                                <li>会員資格の不正使用ないしこれに準じる行為が行われるおそれがある行為が認められると当社が判断したとき</li>
                                <li>度重なる無断キャンセルで他の会員に迷惑をかけていると運営側が判断したとき</li>
                            </ol>
                            <p>前項により除名されたとき、会員は、当社に対し、損害賠償その他何らかの請求を行うことはできません。なお、会費の返金に関しては、第８条を準用します。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第18条(退会・休会・プラン変更)</h3>
                        <div>
                            <p>休会・退会・プラン変更をしようとする会員は、店舗に来店のうえ、別途当社が指定する各種手続きを行うものとします。</p>
                            <ol className={styles.numList}>
                                <li>休会・退会・プラン変更の申し出は毎月10日を締日とし、末日に適用とする。11日以降の申し出は翌月末の適用とする。</li>
                                <li>休会期間中は別途定められた料金（1,000円（税込）/1ヶ月）を支払う。また、休会期間中の退会は出来ないものとする。　</li>
                                <li>未納金が発生している場合の退会・休会申請は、未納金支払い後の受理とする。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第19条(運営管理)</h3>
                        <div>
                            <p>本施設は次の各号に基づき、運営管理を行います。</p>
                            <ol className={styles.numList}>
                                <li>本施設の運営管理は当社の責任において行います。</li>
                                <li>当社は本施設の利用等、運営管理に関する規則を定め、かつこれを必要に応じ変更することができます。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第20条(諸規則遵守)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>会員は本施設の利用に際し、所定の手続きを行うとともに、本規程、細則ならびに会社が別に定める規則に従うものとします。</li>
                                <li>会員は、本施設の提供するマシンの使用にあたり、それぞれ会社が規定する使用上の注意事項を遵守するとともに、使用に伴うトラブルその他の事故並びに混雑時による  利用遅延等に関し、当社に対し、損害賠償その他何らの請求を行わないことを誓約するものとします。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第21条(禁止行為)</h3>
                        <div>
                            <p>会員は、以下に掲げる行為をしてはならないものとします。</p>
                            <ol className={styles.numList}>
                                <li>法令・条例に違反する行為</li>
                                <li>ストーカー行為など、他人の迷惑や本施設利用の妨げとなる行為</li>
                                <li>他人を誹謗・中傷（SNS・インターネット等への書き込み含む）する行為</li>
                                <li>他人に成りすまして本施設を利用する行為</li>
                                <li>当社の業務を妨げる行為</li>
                                <li>他人に対する暴力や本施設設備への落書きなど、公共のマナー・道徳に反する行為</li>
                                <li>ペット・動物を持ち込む行為</li>
                                <li>本施設内での飲酒・喫煙行為</li>
                                <li>他人の本施設利用を妨げる行為</li>
                                <li>お子様同伴での本施設の利用</li>
                                <li>その他上記各号に準じる行為</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第22条(休業日)</h3>
                        <div>
                            <p>毎月各施設の定める日、年末年始、夏季休業、設備点検、修理、施設の改装、並びに会社が別途定める日を休業日とします。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第23条(営業時間)</h3>
                        <div>
                            <p>各施設の定める営業時間とします。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第24条(当社の免責)</h3>
                        <div>
                            <p>会員は、本施設内において、自己及び自己の所有物を自らの責任において管理するものとし当社は本施設内で発生した盗難・傷害その他の事故については、一切の賠償責任を負わないものとします。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第25条(会員の責任)</h3>
                        <div>
                            <p>会員が本施設の利用に関して、当社、他の会員、第三者に損害を与えたときは、その賠償をしていただきます。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第26条(諸料金の改定)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>会社は、入会金、会費、利用料等を、社会、経済情勢の変動を勘案して改定することができます。</li>
                                <li>会社は入会金・会費・利用料を改定する場合には、改定月の１ヶ月前までに会員に告知します。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第27条(変更届)</h3>
                        <div>
                            <p>会員は、氏名・住所・連絡先などに入会申込書の記載事項に変更があった場合には速やかに当社へ変更届を提出するものとします。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第28条(閉鎖又は利用制限)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>当社は、次の各号により本施設の営業が不可能または著しく困難になった場合、本施設を全部又は一部を閉鎖し又は本施設の利用を制限することができ、同時にすべての会員と契約を解除することができます。あらかじめ予定されている場合には本施設の全部を閉鎖する旨は三ヶ月前までに、その他の場合には一ヶ月前までに会員に対してその旨を告知します。</li>
                                <li>当社が重要な財産に対する差押、仮差押、仮処分、租税延滞処分、その他公権力の処分を受け、あるいは破産手続開始、民事再生手続開始、会社更生手続開始、又は特別清算開始の申立が行われたとき</li>
                                <li>当社が解散あるいは事業の全部を譲渡し、又はその決議がなされたとき</li>
                                <li>当社の主要な株主の変更、事業譲渡・合併・会社分割等の組織再編、その他会社の支配に重要な影響を及ぼす事実が生じたとき</li>
                                <li>当社が自ら振り出し若しくは引き受けた手形又は小切手が不渡りとなる等支払停止状態に至ったとき</li>
                                <li>当社が監督官庁から営業停止、又は営業免許若しくは営業登録の取消しの処分を受けた</li>
                                <li>前各号のほか、本施設の利用を継続しがたい事由が生じたとき</li>
                            </ol>
                            <p>前項の場合、会員は、その他名目の如何を問わず、会社に対し、損害賠償責任等の異議申し立てをすることができません。また、本施設の利用を制限する場合には、可能な範囲で本施設を利用できる処置を講じます。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第29条(個人情報保護)</h3>
                        <div>
                            <p>当社は、個人情報の取扱に関する個人情報保護ポリシーを策定し遵守するとともに、会員の個人情報をより安全、適切に取り扱います。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第30条(細則等)</h3>
                        <div>
                            <p>本規程に定めのない事項ならびに運営上必要な事項については別途細則その他の規則に定めます。</p>
                        </div>
                    </section>

                    <section>
                        <h3>第31条(規程の改定)</h3>
                        <div>
                            <ol className={styles.numList}>
                                <li>会社は必要に応じて本規程及び細則等の改正をすることができます。会員は本規程の改正が当然にすべての会員にその効力を及ぼすことを、あらかじめ承認するものとします。</li>
                                <li>当社は前項により規程等を改正するとき、改正の１ヶ月前までに会員に告知します。</li>
                                <li>利用料金や各種手数料は、消費税率の変更に伴い税込価格が変動することがあります。</li>
                            </ol>
                        </div>
                    </section>

                    <section>
                        <h3>第32条(告知方法)</h3>
                        <div>
                            <p>本規程における会員への告知方法は、本施設内への掲示を持って足りるものとします。</p>
                        </div>
                    </section>
                </section>

                <section className={styles.block} id="machine">
                    <h2>マシン利用規約</h2>

                    <section>
                        <h3>BURNING SHAPE 禁止事項</h3>
                        <div>
                            <p>以下のいずれかに当てはまる方は使用をお控え下さい。</p>
                            <ul className={styles.slashList}>
                                <li>医療用電子機器（ペースメーカー、人工心肺、心電計等）をお使いの方</li>
                                <li>心臓疾患のある方</li>
                                <li>血液異常（血液凝固障害や血友病疾患等）</li>
                                <li>悪性腫瘍</li>
                                <li>糖尿病</li>
                                <li>甲状腺疾患</li>
                                <li>肝機能障害</li>
                                <li>医療特定疾患（膠原病等）</li>
                                <li>出血、内出血の可能性のある方</li>
                                <li>妊娠中、又は、妊娠の可能性がある方</li>
                                <li>授乳中の方</li>
                                <li>月経中の方</li>
                            </ul>
                        </div>
                        <div>
                            <p>以下のいずれかに当てはまる方は、必ず医師と相談の上ご使用下さい。</p>
                            <ul className={styles.slashList}>
                                <li>原爆症</li>
                                <li>急性疾患</li>
                                <li>結核性疾患</li>
                                <li>アレルギー疾患</li>
                                <li>法定伝染病</li>
                                <li>顔⾯⿊⽪症</li>
                                <li>38度以上の有熱性疾患</li>
                                <li>喘息や気管支疾患</li>
                                <li>特に肌が敏感の方（ケロイド体質、アトピー性皮膚等）や皮膚疾患（ヘルペス等）</li>
                                <li>局所性、全身ステロイド系、非ステロイド系抗炎症薬やステロイド系ホルモン剤の使用</li>
                                <li>肝機能障害</li>
                                <li>血圧異常・薬物やホルモン治療</li>
                                <li>過去の疾病</li>
                                <li>現在、治療中、持病をお持ちで通院中の方</li>
                                <li>アルコール中毒</li>
                                <li>体調が優れない方（激しい疲労感）</li>
                                <li>極度の空腹や断食中の方</li>
                            </ul>
                        </div>
                        <div>
                            <p>1部位30分以上はマシンを当てないでください。<br />お客様の持病、体調等を原因とする事故については一切責任をお取りできません。</p>
                        </div>
                        <div>
                            <p>使用前の注意事項</p>
                            <ul className={styles.dotList}>
                                <li>時計、金属、アクセサリー類は必ず外してください。</li>
                                <li>必ず指定しておりますクリーム、ローション、ジェルをご使用ください。</li>
                                <li>使用する前に指定しておりますクリーム、ローション、ジェルを必ず手のひらに塗りプローブヘッドをあてて温度を確かめてください。</li>
                                <li>乾いた肌へのマシンのご使用は火傷の原因となりますのでお止めください。</li>
                                <li>使用上の注意を守らずに起こったマシンの故障による火傷や皮膚トラブルなどの事故が発生した場合、当サロンの過失によるないものについては責任を負いかねます。</li>
                                <li>治療中の傷や体内に金属・樹脂・シリコン等を埋め込んだ部位の周囲には使用しないでください。</li>
                                <li>体調がすぐれない方は無理にマシンを使用しないでください。</li>
                                <li>空腹時は避けて下さい。（目安：食後1時間以上）</li>
                                <li>異常に熱い場合等、異常を感じた場合には使用を止め、スタッフにお声がけください。</li>
                                <li>出力のレベルは必ず低いレベルからスタートしてください。</li>
                                <li>揮発性の高いもの（除光液等）は近くで使用しないでください。</li>
                            </ul>
                        </div>
                        <div>
                            <p>以下の部位へ使用しないでください。</p>
                            <ul className={styles.slashList}>
                                <li>粘膜・眼球・口内</li>
                                <li>心臓近く</li>
                                <li>整形手術をした部位</li>
                                <li>傷口や脱毛後間もない部位</li>
                                <li>月経時の腹部・痛覚、知覚障害を起こしている部位</li>
                                <li>皮膚異常のある部位</li>
                                <li>金属、プラスチック、シリコンなどを体内に埋め込んでいる部位</li>
                            </ul>
                        </div>
                        <div>
                            <p>以下の皮膚トラブルをお持ちの方はその部位へ使用しないでください。</p>
                            <ul className={styles.slashList}>
                                <li>ニキビや吹き出物で炎症を起こしている部位</li>
                                <li>化粧品などで皮膚炎症を起こしている部位</li>
                                <li>アトピー、湿疹などの症状がある部位</li>
                                <li>痒みや火照り、物理的刺激による病的なシミのある部位</li>
                            </ul>
                        </div>
                        <div>
                            <p>使用上の注意事項</p>
                            <ul className={styles.dotList}>
                                <li>火傷には十分ご注意ください。</li>
                                <li>使用の際には、指定しておりますクリーム、ジェル、ローションをお使いください。使用中に乾いてしまった場合には必ず足してください。クリームなどが足りない場合は火傷をする可能性があります。</li>
                                <li>各ヘッドは肌に全面を密着させ、一箇所に止めないように動かしてください。</li>
                                <li>各ヘッド使用後は速やかに電源をお切りください。</li>
                                <li>マシン使用中に携帯電話やiPad等電子機器その他の所持品の故障があった場合、当サロンでは一切責任を負いません。</li>
                            </ul>
                        </div>
                        <div>
                            <p>その他の注意事項</p>
                            <ul className={styles.dotList}>
                                <li>各マシンの効果等については個人差があります。</li>
                                <li>各トリートメント使用後は、クリームをふき取るなど清潔な状態にしてください。</li>
                                <li>マシンを使用された日は極端な高温での入浴、サウナ、岩盤浴等身体に負担がかかりすぎる行為は避けてください。</li>
                                <li>マシン使用は毎日でも可能ですが、同じ部位への使用は中1日空けてください。</li>
                                <li>マシンを1時間以上使用する際は、約10分程度電源を切り再度ご使用ください。</li>
                                <li>コードは傷つけたり、破損や加工したり、無理に捻じ曲げたりしないでください。</li>
                                <li>プローブの線は引っ張ったり、巻き付けたりしないようご注意ください。</li>
                                <li>本体や操作パネルを濡らさないでください。</li>
                                <li>サロン内では電話や話し声などは他のお客様のご迷惑になりますので、ご遠慮ください。</li>
                                <li>マシン使用用のお着替えはありませんので、ご了承ください。</li>
                                <li>貴重品はお客様ご自身で管理してください。盗難・紛失等があった場合、当サロンは一切責任を負いません。</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h3>BEAUCOLLA HYBRID 禁止事項</h3>
                        <div>
                            <p>以下のいずれかに当てはまる方は使用をお控え下さい。</p>
                            <ul className={styles.slashList}>
                                <li>体調が優れない方</li>
                                <li>光アレルギーがある、または光に敏感になる薬を服用している方</li>
                                <li>重度のアトピー性皮膚炎、炎症中の皮膚炎などの症状がある方</li>
                                <li>ポリフィリン症にかかっている方</li>
                                <li>てんかんを起こすことがある方</li>
                                <li>コルチゾンまたはステロイドを注射した方</li>
                                <li>抗生物質、睡眠薬をのまれている方（常用してる薬がある方）</li>
                                <li>アルコールを飲まれている方</li>
                                <li>妊娠中または、産後間もない方</li>
                                <li>ペースメーカーなどの医療機器をご利用されている方</li>
                            </ul>
                            <p>以上の注意を守らずにマシンを使用してトリートメントを行い、皮膚などのトラブルや当サロンで各種事故が発生した場合には、当サロンの過失によらないものについては責任を負いかねますのであらかじめご了承ください。また、今後、当サロンが導入するすべてのマシンについて使い方ファイルやiPadに記載された使用上の注意を守らずに各種事故が起こった場合も当サロンの過失によらないものについては責任を負いかねます。</p>
                        </div>
                    </section>

                </section>
            </div>
        </div>
    )
}

export default AgreementPage
